<template>
    <div>
        <!-- ArticlesSections.vue -->
        <HeaderBg title="أقسام المنتجات" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCourse" 
            messege="هل أنت متأكد من مسح القسم   ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                
            </div>
            <router-link to="/store-admin/categories/add-category"> أضف قسم جديد </router-link>
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="divsTable" v-if="loadingRequest == false">
            <div class="line_row">
                <h3>الأقسام الرئيسية</h3>
            </div>
            <div class="line_row" v-for="category in categories" :key="category.id">
                <h4 class="mr-4">{{category.translation.length > 0 ? category.translation[0].name : ''}}</h4>
                
                <div class="actionsContainer">
                    <button :class="category.is_featured == true ? 'disableBtn' : 'saveBtn'" :disabled="category.is_featured == true" v-if="postLoaded == false" @click="() => addFeature(category.id)"> مميز </button>
                    <button :class="category.is_featured == true ? 'disableBtn' : 'saveBtn'" v-if="postLoaded == true"><b-spinner></b-spinner></button>
                    <button @click="() => subSections(category.id)">أقسام فرعية<b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon></button>
                </div>
            </div>
        </div>
        <div class="pagination" v-if="categories.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";

export default {
    name: 'ProductsCategories',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            postLoaded: false,
            categories: [],
            deleteID: '',
            alertToggle: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        this.getCategories();

        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
    },
    methods:{
        addFeature(id){
            this.postLoaded = true
            Request.post(`admin/categories/${id}/feature`,'', '')
            .then(() => {
                this.postLoaded = false
                this.getCategories();
            })
            .catch(err => {
                this.postLoaded = false
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        subSections(id){
            this.$router.push(`/store-admin/sub-category/${id}`)
        },
        getCategories(){
            Request.fetch('admin/category', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.categories = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        
        editBranch(id){
            this.$router.push(`/store-admin/categories/add-category/${id}`)
        },
            // delete data
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCourse(){
            Request.delete('admin/category',this.deleteID)
            .then( () => {
                this.getCategories();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getCategories();
            } else{
                this.apiParams = `?page=${val}`;
                this.getCategories();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.saveBtn,
.disableBtn{
    margin-top: 0;
}
.actionsContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button{
        background-color: #D8D253;
        border: 10px;
        padding: 5px 20px;
        color: #FFF;
        border: 0;
        margin-left: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            margin-right: 10px;
        }
    }
}

</style>