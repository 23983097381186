<template>
    <div class="walletSection">
        <div class="input_container">
            <label>مبلغ المحفظة :</label>
            <input type="text" v-model="amount">
            <div class="wallet_actions d-flex mt-4">
                <button @click="() => addWallet('bonus')" class="saveBtn" v-if="postLoaded == false"> مكافئة </button>
                <button @click="() => addWallet('refund')" class="saveBtn" v-if="postLoaded == false"> تعويض </button>
                <button @click="() => addWallet('adjustment')" class="saveBtn" v-if="postLoaded == false"> تعديل </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </div>
        </div>

        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> المبلغ</th>
                        <th> المبلغ قبل</th>
                        <th> المبلغ بعد</th>
                        <th> التاريخ </th>
                        <th> نوع العميلة </th>
                        <th> شرح </th>
                        
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="wallet in wallet" :key="wallet.id">
                        <td> {{wallet.amount}} </td>
                        <td> {{wallet.balance_before}} </td>
                        <td> {{wallet.balance_after}} </td>
                        <td> {{wallet.created_at}} </td>
                        <td> {{wallet.transaction_type}} </td>
                        <td> {{wallet.description}} </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>     
</template>
<script>
import axios from 'axios';
import Request from '../../../../services/Request';
import RequestSpinner from '../../../global/loadingSpinners/RequestSpinner';
export default {
    name: 'UserWallet',
    components: {RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            postLoaded: false,
            amount: '',
            wallet: [],

            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getWallet();
    },
    methods:{
        addWallet(type){
            this.postLoaded = true;
            axios.post(`https://app.almujtama.com.sa/admin/wallet/${type}/${this.$route.params.id}`, { amount : this.amount}, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.postLoaded = false;
                this.loadingRequest = true;
                this.getWallet();
            })  
            .catch(err =>  {
                this.errors = err.response.data.errors;
                this.ErrorCheck = true;
                this.postLoaded = false;
                
            })
        },
        getWallet(){
            if(this.$route.params.id !== undefined){
                axios.get(`https://app.almujtama.com.sa/admin/wallet/${this.$route.params.id}/transactions`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Authorization': 'Bearer '+ localStorage.getItem('token'),
                    },
                })
                .then((response) => {
                    console.log(response, 'wallet')
                    this.wallet = response.data.data
                    this.loadingRequest = false;
                })
                .catch((err) => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
    }

}
</script>
<style lang="scss" scoped>
    .input_container{
        margin-bottom: 16px;
        width: 30%;
        @media (max-width: 1440px) {
            width: 45%;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        label{
            font-size: 14px;
            white-space: nowrap;
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        input,
        select,
        .b-form-datepicker{
            background-color: #FFF;
            border-radius: 10px;
            padding: 10px;
            font-size: 14px;
            border: 1px solid #C5C5C5;
            width: 100%;
            &::placeholder{
                font-size: 14px;
                color: #C5C5C5;
            }
        }
        .b-form-datepicker{
            padding: 5px;
        }
    }
    .wallet_actions{
        margin-bottom: 50px;
        .saveBtn{
            margin:  0 10px 0 0;
        }
    }
</style>